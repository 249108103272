import React from 'react'
import { HomeBanner, ImageBox, StyledImage, bannerMinHeights } from '../../components/banner/HomeBanner'
import { Container } from '@material-ui/core'
import styled from 'styled-components'
import Link from 'next/link'
import { CreditUnionLogo, StyledLogoContainer } from '../../components/logo/logo'

const Styled404Banner = styled.div`
  ${bannerMinHeights};
`

const StyledMobileLogo = styled.div`
  display: block;
  ${props => props.theme.breakpoints.up('sm')} {
    margin: 0 0 ${props => props.theme.typography.pxToRem(20)};
  }
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
  ${StyledLogoContainer} {
    display: block;
  }
`

const StyledHomeBanner = styled(HomeBanner)`
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => props.theme.typography.pxToRem(70)};
  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
  }
  ${ImageBox} {
    margin: 0 ${props => props.theme.typography.pxToRem(30)} 0 ${props => props.theme.typography.pxToRem(30)};
    ${props => props.theme.breakpoints.up('md')} {
      margin: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(10)} 0 ${props => props.theme.typography.pxToRem(10)};
    }
  }
  ${StyledImage} {
    > div {
      max-height: 100%;

      > img {
        height: unset !important;
        min-height: unset !important;
      }
    }
  }

  > div:first-child {
    z-index: 2000;
    position: relative;
    ${props => props.theme.breakpoints.up('md')} {
      position: static;
    }
  }

  > div:last-child {
    margin-top: 0;
    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: ${props => props.theme.typography.pxToRem(120)};
    }

    > h1:first-of-type {
      font-size: ${props => props.theme.typography.pxToRem(17)};
      line-height: ${props => props.theme.typography.pxToRem(32)};
      margin-bottom: ${props => props.theme.typography.pxToRem(32)};
    }

    > h1:last-of-type {
      font-size: ${props => props.theme.typography.pxToRem(40)};
      line-height: ${props => props.theme.typography.pxToRem(52)};
      margin-bottom: ${props => props.theme.typography.pxToRem(21)};
      ${props => props.theme.breakpoints.up('sm')} {
        font-size: ${props => props.theme.typography.pxToRem(60)};
        line-height: ${props => props.theme.typography.pxToRem(72)};
      }
    }

    > p {
      font-size: ${props => props.theme.typography.pxToRem(17)};
      line-height: ${props => props.theme.typography.pxToRem(30)};
      margin: 0 0 ${props => props.theme.typography.pxToRem(57)};
      display: block;
      ${props => props.theme.breakpoints.up('sm')} {
        font-size: ${props => props.theme.typography.pxToRem(18)};
        line-height: ${props => props.theme.typography.pxToRem(35)};
      }

      &:last-child {
        margin: 0;
      }
    }
  }
`

const StyledHomeContainer = styled(Container)`
  padding: 0;
`

const StyledErrorContentWrapper = styled(Container)`
  margin-top: ${props => props.theme.typography.pxToRem(22)};

  > figure {
    margin-top: ${props => props.theme.typography.pxToRem(22)};

    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: ${props => props.theme.typography.pxToRem(119)};
    }
  }
`

export type ErrorProps = {
  statusCode?: '404' | '500'
}

export const Error: React.FC<ErrorProps> = ({ statusCode }) => {
  return (
    <StyledHomeContainer maxWidth={false}>
      <StyledErrorContentWrapper>
        <CreditUnionLogo />
        <Styled404Banner>
          <StyledHomeBanner image width={890} height={990} priority={true} src={`/images/${statusCode}.png`} i={-1}>
            <StyledMobileLogo>
              <CreditUnionLogo />
            </StyledMobileLogo>
            <HomeBanner title text={`ERROR ${statusCode}`} />
            <HomeBanner title text={'Oops !'} />
            <HomeBanner subtitle text={
              statusCode === '404'
                ? 'Sorry we can’t seem to find the page you’re looking for, maybe it’s a bad link or maybe it was mistyped.'
                : 'Something went wrong, the server is not responding. Please try again later.'
            }/>
            <HomeBanner subtitle text={(
              <>
                  Visit our <Link href="https://www.creditunionsa.com.au/">homepage</Link> to discover more products and tools or <Link href="/help-and-support/general-enquiries">contact us</Link>
              </>
            )} />

          </StyledHomeBanner>
        </Styled404Banner>
      </StyledErrorContentWrapper>
    </StyledHomeContainer>
  )
}
